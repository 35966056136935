import React from 'react';

const IconArxiv = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 17.732 24.269">
    <title>arXiv</title>
    <path d="m 1.1015625 0.00390625 A 1.09 1.09 0 0 0 0.07421875 0.66210938 C -0.09278125 1.0621094 0.026578125 1.3441406 0.39257812 1.8691406 L 8.8320312 12.111328 L 8.8300781 12.113281 L 11.107422 14.857422 L 12.535156 13.53125 A 1.924 1.924 0 0 0 12.541016 10.677734 L 1.9355469 0.50390625 A 1.413 1.413 0 0 0 1.1015625 0.00390625 z M 14.796875 2.4003906 A 0.953 0.953 0 0 0 14.085938 2.6621094 L 9.0664062 7.1367188 L 11.263672 9.2460938 L 15.505859 4.265625 C 15.858859 3.795625 16.024422 3.5484375 15.857422 3.1484375 A 1.218 1.218 0 0 0 14.796875 2.4003906 z M 6.4414062 9.4472656 L 5.15625 10.605469 C 4.3185313 11.416226 4.3185313 12.759555 5.15625 13.570312 L 15.96875 23.990234 C 16.161311 24.190752 16.433848 24.294029 16.710938 24.271484 C 17.134757 24.264684 17.511953 24.00111 17.664062 23.605469 C 17.789641 23.162554 17.663864 22.686403 17.335938 22.363281 L 11.09375 15.076172 L 8.6289062 12.105469 L 8.6308594 12.103516 L 6.4414062 9.4472656 z M 6.4570312 15.029297 L 2.5507812 19.826172 A 1.336 1.336 0 0 0 2.2285156 21.126953 A 1.114 1.114 0 0 0 3.2675781 21.816406 A 0.992 0.992 0 0 0 4.015625 21.451172 L 8.6484375 17.142578 L 6.4570312 15.029297 Z "></path>
  </svg>
);

export default IconArxiv;
