import React from 'react';

const IconDocs = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 1665.3282 1536.0001">
    <title>Documentation</title>
    <path
      d="m 1639.5217,350.00001 q 40,57 18,129 l -275,906.00009 q -19,64 -76.5,107.5 -57.5,43.5 -122.5,43.5 H 260.52174 q -77,0 -148.5,-53.5 -71.500001,-53.5 -99.500001,-131.5 -24,-67 -2,-127 0,-4 3,-27 3,-23 4,-37 1,-8 -3,-21.5 -4,-13.5 -3,-19.5 2,-11 8,-21 6,-10 16.5,-23.5 10.5,-13.5 16.5,-23.5 23,-38 45,-91.50005 22.000001,-53.50004 30.000001,-91.50004 3,-10 0.5,-30 -2.5,-20 -0.5,-28 3,-11 17,-28 14,-17 17,-23 21,-36 42,-92 21,-56 25,-90 1,-9 -2.5,-32 -3.5,-23 0.5,-28 4,-13 22,-30.5 18,-17.5 22,-22.5 19,-26 42.5,-84.5 23.5,-58.5 27.5,-96.5 1,-8 -3,-25.5 -4,-17.5 -2,-26.5 2,-8 9,-18 7,-10 18,-23 11,-13 17,-21 8,-12 16.5,-30.5 8.5,-18.5 15,-35 6.5,-16.500002 16,-36.000002 9.5,-19.5 19.5,-32.000004 10,-12.500004 26.5,-23.500004 16.5,-11 36,-11.5 19.5,-0.5 47.5,5.5 l -1,3 q 38,-9 51,-9 h 760.99996 q 74,0 114,56.000008 40,56.000002 18,130.000002 l -274,906.00009 q -36,119 -71.5,153.5 -35.5,34.5 -128.5,34.5 H 156.52174 q -27,0 -38,15 -11,16 -1,43 24,70 144,70 h 922.99996 q 29,0 56,-15.5 27,-15.5 35,-41.5 l 300,-987.00009 q 7,-22 5,-57 38,15 59,43 z m -1063.99996,2 q -4,13 2,22.5 6,9.5 20,9.5 h 607.99996 q 13,0 25.5,-9.5 12.5,-9.5 16.5,-22.5 l 21,-64 q 4,-13 -2,-22.5 -6,-9.5 -20,-9.5 H 638.52174 q -13,0 -25.5,9.5 -12.5,9.5 -16.5,22.5 z m -83,256 q -4,13 2,22.5 6,9.5 20,9.5 h 607.99996 q 13,0 25.5,-9.5 12.5,-9.5 16.5,-22.5 l 21,-64 q 4,-13 -2,-22.5 -6,-9.5 -20,-9.5 H 555.52174 q -13,0 -25.5,9.5 -12.5,9.5 -16.5,22.5 z"
    />
  </svg>
);

export default IconDocs;
